exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-institution-js": () => import("./../../../src/pages/institution.js" /* webpackChunkName: "component---src-pages-institution-js" */),
  "component---src-pages-sme-js": () => import("./../../../src/pages/sme.js" /* webpackChunkName: "component---src-pages-sme-js" */),
  "component---src-pages-summit-event-js": () => import("./../../../src/pages/summit-event.js" /* webpackChunkName: "component---src-pages-summit-event-js" */),
  "component---src-pages-summit-pricing-form-js": () => import("./../../../src/pages/summit-pricing-form.js" /* webpackChunkName: "component---src-pages-summit-pricing-form-js" */),
  "component---src-pages-summit-pricing-js": () => import("./../../../src/pages/summit-pricing.js" /* webpackChunkName: "component---src-pages-summit-pricing-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

